<template>
  <div class="inline">
    <span
      ref="trigger"
      class="trigger"
      @mouseenter="show"
      @focus="show"
      @mouseleave="hide"
      @blur="hide"
      ><slot /></span><div ref="tooltip" class="tooltip text-black" :data-show="isVisible">
      <span class="text" v-html="text"/>
      <div class="arrow" data-popper-arrow />
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      popperInstance: {},
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.popperInstance = createPopper(
        this.$refs.trigger,
        this.$refs.tooltip,
        {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ],
          placement: 'top',
        }
      )
    })
  },
  methods: {
    show() {
      // Make the tooltip visible
      this.isVisible = true

      // Enable the event listeners
      this.popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: true },
        ],
      }))

      // Update its position
      this.popperInstance.update()
    },
    hide() {
      // Make the tooltip visible
      this.isVisible = false

      // Enable the event listeners
      this.popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: false },
        ],
      }))
    },
  },
}
</script>

<style scoped>
.tooltip {
  @apply hidden bg-white shadow-xl rounded-lg p-2 z-10 max-w-xs;
}

.tooltip[data-show='true'] {
  @apply block;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.tooltip[data-popper-placement^='top'] > .arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^='bottom'] > .arrow {
  top: -4px;
}

.tooltip[data-popper-placement^='left'] > .arrow {
  right: -4px;
}

.tooltip[data-popper-placement^='right'] > .arrow {
  left: -4px;
}
</style>

<!--<template>
  <div class="relative inline">
    <slot class="content" />
    <div
      class="tooltip rounded-xl bg-white shadow-xl text-center p-4 opacity-0 transition-opacity duration-200 ease-in-out"
    >
      <span
        class="text"
      >{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.content:hover + .tooltip{
  opacity: 1;
}

.tooltip {
  bottom: 105%;
  left: 50%;
  margin-left: -60px;
  position: absolute;
  z-index: 1;
}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}
</style>
-->
